<template>
  <div class="dates-path">
    <div class="date-text"> {{ format(since) }}</div>
    <div class="path">
      <div class="line"></div>
      <div class="left-arrow fa fa-caret-left"></div>
    </div>
    <div class="date-text"> {{ format(until) }}</div>
  </div>
</template>

<script>

import moment from 'moment';
import dates from '../../../../utils/dates';

export default {
  name: 'DatesPath',
  props: {
    since: {
      required: true,
      type: String,
    },
    until: {
      required: true,
      type: String,
    },
  },
  methods: {
    format(date) {
      const dayInMonth = moment(date).format('DD');
      const hebrewMonth = dates.getHebrewMonth(date);
      return `${dayInMonth} ל${hebrewMonth}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .dates-path {
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date-text {
      font-family: SimplerPro,sans-serif;
      font-size: 11px;
    }

    .path {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 50%;
      .line {
        width: 100%;
        height: 1px;
        background: $riseup_black;
      }

      .left-arrow {
        margin-left: 2px;
        color: $riseup_black;
        font-size: 13px;
      }
    }

  }
</style>
