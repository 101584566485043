<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="subscription-cta-popup">
    <template v-slot:title>
      <div class="title-content">
        <subscription-cta-popup-content :title="contentTemplateByTrialPeriod.title"
                                        :content="contentTemplateByTrialPeriod.content" />
        <div class="trial-period-text">
          <span class="days-left" :class="periodVariant">{{ daysLeftText }}</span>
          <span v-if="!isFinalTrialPeriod"> לתקופת הניסיון</span>
        </div>
        <div class="progress-bar-container">
          <simple-progress-bar :variant="periodVariant" :progressInPercentage="trialProgressInPercentage" @progressBarClicked="progressBarClicked"/>
        </div>
        <dates-path :since="since" :until="until"/>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-content">
        <subscription-benefit-details v-if="eligibleForBenefit"/>
        <div v-else>
          כדי להמשיך וליהנות מהשירות גם אחרי סיום תקופת הניסיון, כדאי לעדכן את פרטי התשלום כאן.
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div @click="dismissButtonClicked">תזכירו לי בפעם אחרת</div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import Intercom from '@/Intercom';
import DatesPath from './DatesPath.vue';
import SimpleProgressBar from './SimpleProgressBar.vue';
import router from '../../../../router';
import Segment from '../../../../Segment';
import { TRIAL_PERIODS } from '../../../../constants/subscription-state';
import SubscriptionBenefitDetails from './SubscriptionBenefitDetails';
import trialUtils from './trial-utils';
import CustomerBenefits from '../../../../mixins/CustomerBenefits';
import SubscriptionCtaPopupContent from './SubscriptionCtaPopupContent';

export default {
  name: 'SubscriptionCtaPopup',
  components: {
    SimpleProgressBar,
    DatesPath,
    SubscriptionCtaPopupContent,
    SubscriptionBenefitDetails,
    Popup: BaseUI.Popup,
  },
  mixins: [CustomerBenefits],
  data() {
    return {
      PERIOD_TO_CONTENT_MAPPING: {
        [TRIAL_PERIODS.BEGINNING]: {
          title: 'איזה כיף שיצאנו לדרך!',
          content: 'אנחנו מקווים שהשירות יעזור לך לצמוח כלכלית ואנחנו כאן לאורך הדרך, זמינים לך בכל שאלה.',
        },
        [TRIAL_PERIODS.MIDDLE]: {
          title: 'מרכזים את הכל במקום אחד!',
          content: 'בכל כניסה לתזרים שלך אפשר לראות את כל הנתונים מכל חשבונות הבנק וכרטיסי האשראי.',
        },
        [TRIAL_PERIODS.FINAL]: {
          title: 'ידעת שכל כניסה לשירות משפרת את התזרים שלך?',
          content: 'לפי מחקר שביצעה חברת ERI - לקוחות שנכנסים יותר רואים צמיחה מהר יותר.',
        },
      },
      PERIOD_TO_CONTENT_MAPPING_PROMOTIONS_TEST: {
        [TRIAL_PERIODS.BEGINNING]: {
          title: 'אפשר להמשיך לצמוח ביחד!',
          content: 'חודש אחרי חודש, רייזאפ מחזירה את השליטה לידיים שלך בדרך למציאות כלכלית טובה יותר.',
        },
        [TRIAL_PERIODS.MIDDLE]: {
          title: 'אפשר להמשיך לצמוח ביחד!',
          content: 'כל כניסה לתזרים עוזרת לך להיות בשליטה ולחיות טוב יותר עם הכסף שלך.',
        },
        [TRIAL_PERIODS.FINAL]: {
          title: 'אפשר להמשיך לחיות טוב יותר עם הכסף שלך!',
          content: 'בסקר שעשינו גילינו שלקוחות riseup שיפרו את התזרים החודשי ב-₪1,100 בממוצע והתחילו לחסוך.',
        },
      },
    };
  },
  computed: {
    ...mapState('subscriptionState', ['since', 'until']),
    ...mapGetters('subscriptionState', ['daysLeft', 'trialLength', 'trialPeriod']),
    ...mapState('benefits', ['benefitType', 'benefitAmount']),
    ...mapGetters('benefits', ['BENEFIT_TYPES', 'isPercentageDiscountBenefit']),
    ...mapGetters('featureFlags', ['jitSubscriptionPopup']),
    eligibleForBenefit() {
      return this.benefitType;
    },
    periodVariant() {
      return trialUtils.periodVariant(this.trialPeriod);
    },
    contentTemplateByTrialPeriod() {
      const contentMapping = this.PERIOD_TO_CONTENT_MAPPING;
      return contentMapping[this.trialPeriod];
    },
    daysLeftText() {
      const humanizedDays = trialUtils.humanizeDays(this.daysLeft);
      return humanizedDays || 'תקופת הניסיון הסתיימה';
    },
    trialProgressInPercentage() {
      return (this.daysLeft / this.trialLength) * 100;
    },
    buttons() {
      const cta = this.eligibleForBenefit ? 'להרשמה וקבלת ההטבה' : 'להרשמה עם כרטיס אשראי';
      const buttons = [{
        title: cta,
        action: () => {
          Segment.trackUserInteraction('SubscriptionCtaPopup_SubscribeButtonClicked', { trialPeriod: this.trialPeriod });
          this.$emit('close');
          router.push({ path: '/subscription' });
        },
        variant: 'primary',
        size: 'slim',
      }];
      if (this.jitSubscriptionPopup) {
        buttons.push({
          title: 'לצ׳אט עם הצוות',
          action: this.handleIntercomClickAction,
          variant: 'secondary',
          size: 'slim',
          // eslint-disable-next-line global-require
          icon: require('@/assets/icons/chat_icon_secondary.svg'),
        });
      }
      return buttons;
    },
    isFinalTrialPeriod() {
      return this.trialPeriod === TRIAL_PERIODS.FINAL;
    },
  },
  created() {
    Segment.trackUserInteraction('SubscriptionCtaPopupShown', { trialPeriod: this.trialPeriod, benefitType: this.benefitType });
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.$options.name, isButtonShown: this.jitSubscriptionPopup });
  },
  methods: {
    ...mapActions('subscriptionState', ['ctaDismissed']),
    closeActionClicked() {
      Segment.trackUserInteraction('SubscriptionCtaPopup_CloseButtonClicked', { trialPeriod: this.trialPeriod });
      this.subscriptionCtaDismissed();
    },
    dismissButtonClicked() {
      Segment.trackUserInteraction('SubscriptionCtaPopup_DismissButtonClicked', { trialPeriod: this.trialPeriod });
      this.subscriptionCtaDismissed();
    },
    subscriptionCtaDismissed() {
      this.ctaDismissed();
      this.$emit('close');
    },
    progressBarClicked() {
      Segment.trackUserInteraction('SubscriptionCtaPopup_ProgressBarClicked', { trialPeriod: this.trialPeriod });
    },
    handleIntercomClickAction() {
      Segment.trackUserInteraction('SubscriptionCtaPopup_IntercomButtonClicked', { trialPeriod: this.trialPeriod });
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .subscription-cta-popup {
    ::v-deep .riseup-button .button-content .button-icon {
      display: inline-block;
      margin-left: 6px;
    }
    .title-content {
      font-family: SimplerPro;
      font-size: 14px;

      .trial-period-text {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 16px;
        font-family: SimplerPro,sans-serif;

        .days-left  {
          font-weight: bold;

          &.warning {
            color: $riseup_yellow;
          }

          &.danger {
            color: $riseup_danger_red;
          }
        }
      }

      .dates-path {
        margin-top: 3px;
      }

      .progress-bar-container {
        position: relative;
        height: 27px;

        .simple-progress-bar {
          position: absolute;
          width: 100%;
        }
      }
    }

    .content-content {
      margin-top: 30px;
      line-height: 1.5;
      .discount {
        font-size: 14px;

        .gift {
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
    .green {
      background-color: $riseup_light_green;
    }
  }
</style>
