<template>
  <div>
    <div class="content-title">
      <div>{{title}}</div>
    </div>
    <div class="content-text">
      <div>{{content}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SubscriptionCtaPopupContent',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.content-text {
  line-height: 1.5;

  div {
    font-size: 16px;
  }
}

.content-title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
}

</style>
