<template>
  <div class="simple-progress-bar round-border" :class="variant" @click="$emit('progressBarClicked')">
    <div class="progressbar round-border" :style="width" :class="variant"></div>
  </div>
</template>

<script>
export default {
  name: 'SimpleProgressBar',
  props: {
    variant: {
      type: String,
      required: true,
    },
    progressInPercentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    width() {
      return `width: ${this.progressInPercentage}%`;
    },
  },
};
</script>

<style scoped lang="scss">

  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  .simple-progress-bar {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 2px 0;
    width: 100%;
    height: 16px;

    &.warning {
      background: rgba($riseup_yellow, 0.1);
    }

    &.danger {
      background: rgba($riseup_danger_red, 0.1);
    }
  }

  .progressbar {
    position: absolute;
    height: 100%;
    min-width: 34px;

    &.warning {
      background: $riseup_yellow;
    }

    &.danger {
      background: $riseup_danger_red;
    }
  }
  .round-border {
    border-radius:17px;
  }
</style>
