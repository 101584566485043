<template>
  <div class="subscription-benefit-details">
    <div>
      <div class="gift" v-if="monthsFree">{{ monthFreeDescription }}</div>
      <div class="gift" v-else-if="percentageDiscountPromotion">🎁  מגיעה לך הנחה על דמי המינוי!</div>
      <div class="gift" v-else-if="secondMonthFree">🎁 מגיע לך חודש במנוי במתנה!</div>
      כדי להמשיך וליהנות מהשירות גם לאחר תקופת הניסיון ולנצל את ההטבה אפשר להקליק ולעבור למסך פרטי התשלום.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SubscriptionBenefitDetails',
  computed: {
    ...mapState('benefits', ['benefitType', 'benefitAmount']),
    ...mapGetters('benefits', ['BENEFIT_TYPES']),
    monthsFree() {
      return this.benefitType === this.BENEFIT_TYPES.FREE_MONTHS;
    },
    percentageDiscountPromotion() {
      return this.benefitType === this.BENEFIT_TYPES.PERCENTAGE_DISCOUNT;
    },
    monthFreeString() {
      if (this.benefitAmount === 1) {
        return 'חודש נוסף';
      }
      if (this.benefitAmount === 2) {
        return 'חודשיים נוספים';
      }
      return `${this.benefitAmount} חודשים נוספים`;
    },
    secondMonthFree() {
      return this.benefitType === this.BENEFIT_TYPES.SECOND_MONTH_FREE;
    },
    monthFreeDescription() {
      return `🎁 מגיע לך ${this.monthFreeString} ללא חיוב מתנה!`;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
.subscription-benefit-details {
  font-size: 14px;

  .gift {
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>
